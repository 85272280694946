/* istanbul ignore file */

import "./StyledCarousel.css";
import * as React from "react";
import { FunctionComponent, ReactNode } from "react";
import * as PropTypes from "prop-types";
import Carousel from "nuka-carousel";
import * as ArrowSource from "../../images/arrow-icon.png";

interface CarouselProps {
  children?: ReactNode;
  autoplayInterval?: number;
}

const StyledCarousel: FunctionComponent<CarouselProps> = (
  props
): JSX.Element => {
  const { autoplayInterval } = props;
  return (
    <Carousel
      className="styled-carousel"
      wrapAround={true}
      renderCenterLeftControls={({ previousSlide }) => (
        <button onClick={previousSlide}>
          <img src={ArrowSource} alt="previous arrow icon" />
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button onClick={nextSlide}>
          <img src={ArrowSource} alt="next arrow icon" />
        </button>
      )}
      renderBottomCenterControls={() => null}
      autoplay={true}
      autoplayInterval={autoplayInterval}
    >
      {props.children}
    </Carousel>
  );
};

StyledCarousel.propTypes = {
  children: PropTypes.node,
  autoplayInterval: PropTypes.number
};

export default StyledCarousel;
