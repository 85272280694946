import * as React from "react";
import * as PropTypes from "prop-types";
const slugify = require("slugify");
import { Highlight } from "react-instantsearch-dom";
import { Link } from "../../../components";

const hitComponentPropsValidation = {
  hit: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    excerpt: PropTypes.string
  })
};

export const SearchHitComponent = ({ hit }) => (
  <div>
    <Link href={hit.url}>
      <Highlight attribute="title" hit={hit} tagName="mark" />
    </Link>
    <Highlight attribute="excerpt" hit={hit} tagName="mark" />
  </div>
);

SearchHitComponent.propTypes = hitComponentPropsValidation;
export const SearchHit = clickHandler => SearchHitComponent;

export const PostHitComponent = ({ hit }) => {
  return (
    <div>
      <Link href={`/news/?title=${slugify(hit.title).toLowerCase()}`}>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </Link>
      <Highlight attribute="excerpt" hit={hit} tagName="mark" />
    </div>
  );
};

PostHitComponent.propTypes = hitComponentPropsValidation;

export const PostHit = clickHandler => PostHitComponent;
