import * as React from "react";

import "./Section.css";

const Section: React.FunctionComponent<
  React.HTMLAttributes<HTMLElement>
> = function(_props) {
  const { className, children, ...props } = _props;
  const computedClassName = className
    ? `page__section ${className}`
    : "page__section";

  return (
    <section className={computedClassName} {...props}>
      {children}
    </section>
  );
};

export default Section;
