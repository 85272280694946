import * as React from "react";
import { FunctionComponent } from "react";
import * as PropTypes from "prop-types";

import Checkbox from "../Checkbox/Checkbox";
import DateInput from "../DateInput/DateInput";
import FileInput from "../FileInput/FileInput";
import NumberInput from "../NumberInput/NumberInput";
import TextArea from "../TextArea/TextArea";
import TextInput from "../TextInput/TextInput";

import "./Input.css";

type InputProps = {
  className?: string;
} & JSX.IntrinsicElements["input"];

const Input: FunctionComponent<InputProps> = (props): JSX.Element => {
  const { className, ..._props } = props;
  let classNames = `input`;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let InputComponent = null;

  switch (props.type) {
    case "checkbox":
      InputComponent = Checkbox;
      break;
    case "date":
      InputComponent = DateInput;
      break;
    case "number":
      InputComponent = NumberInput;
      break;
    case "file":
      InputComponent = FileInput;
      break;
    case "textarea":
      InputComponent = TextArea;
      break;
    default:
      InputComponent = TextInput;
  }

  return <InputComponent className={classNames} {..._props} />;
};

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string
};

Input.defaultProps = {
  type: "text"
};

export default Input;
