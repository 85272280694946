import "./Dropdown.css";
import * as React from "react";

type DropdownProps = JSX.IntrinsicElements["select"];

const Dropdown: React.FunctionComponent<DropdownProps> = props => {
  return <select {...props} className="select" />;
};

export default Dropdown;
