import * as React from "react";
import { Fragment, FunctionComponent } from "react";
import * as PropTypes from "prop-types";

import "./Checkbox.css";

type CheckboxProps = {
  id: string;
} & JSX.IntrinsicElements["input"];

const Checkbox: FunctionComponent<CheckboxProps> = (props): JSX.Element => {
  return (
    <Fragment>
      <input {...props} className="visually-hidden" type="checkbox" />
      <label className="input input__checkbox" htmlFor={props.id} />
    </Fragment>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired
};

export default Checkbox;
