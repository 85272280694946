import "./TestimonialCarouselCard.css";
import * as React from "react";
import { Component, ReactNode } from "react";
import * as PropTypes from "prop-types";

interface TestimonialCarouselCardProps {
  children?: ReactNode;
  quote?: string;
  name?: string;
  location?: string;
  company?: string;
  imgSrc?: any;
}

class TestimonialCarouselCard extends Component<TestimonialCarouselCardProps> {
  public static readonly propTypes = {
    children: PropTypes.node
  };
  public render(): JSX.Element {
    return (
      <div className="card__wrapper">
        {this.props.quote && (
          <h3 className="card__title">&quot;{this.props.quote}&quot;</h3>
        )}
        <div className="card__testimonial">{this.props.children}</div>
        <div className="card__details">
          <div>
            {this.props.imgSrc && (
              <img src={this.props.imgSrc} alt="testimonial profile picture" />
            )}
          </div>
          <div>
            {this.props.name && <p>{this.props.name}</p>}
            {this.props.company && <p>{this.props.company}</p>}
            {this.props.location && <p>{this.props.location}</p>}
          </div>
        </div>
      </div>
    );
  }
}

export default TestimonialCarouselCard;
