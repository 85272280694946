import * as React from "react";

import "./Tile.css";
import * as propTypes from "prop-types";

const Tile: React.FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = function(_props) {
  const { className, ...props } = _props;
  const computedClassName = className ? `tile ${_props.className}` : "tile";

  return (
    <div className={computedClassName} {...props}>
      {_props.children}
    </div>
  );
};

Tile.propTypes = {
  className: propTypes.string
};

export default Tile;
