import "../TextInput/TextInput.css";
import * as React from "react";
import { FunctionComponent } from "react";

type TextInputProps = {} & JSX.IntrinsicElements["input"];

const DateInput: FunctionComponent<TextInputProps> = (props): JSX.Element => {
  return <input {...props} className="input input__text" type="date" />;
};

export default DateInput;
