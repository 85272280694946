import "./FileInput.css";
import * as React from "react";
import { Fragment, PureComponent } from "react";
import * as PropTypes from "prop-types";

type FileInputProps = {
  id: string;
} & JSX.IntrinsicElements["input"];

interface FileInputState {
  files: File[];
  isFocused: boolean;
}

function isFileTooLarge(file: File, max: number) {
  return file.size > max;
}

class FileInput extends PureComponent<FileInputProps, FileInputState> {
  public state = {
    files: [],
    isFocused: false
  };

  private static defaultProps = {
    type: "file"
  };

  private static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["file"])
  };

  private onChange = event => {
    const input = event.target;
    const files = [...input.files];

    // Check that every file is under the max file size limit
    const isValid = files.reduce((isValid, file) => {
      if (!isValid) {
        return false;
      }
      return !isFileTooLarge(file, Math.pow(2, 20) * 4.5); // 4.5 MiB
    }, true);

    if (!isValid) {
      input.setCustomValidity("File is too large. Max file size limit 4.5MB.");
      input.reportValidity();
      return this.setState({
        files: []
      });
    }

    this.setState({
      files: files
    });
  };

  private onFocus = () => {
    this.setState({
      isFocused: true
    });
  };

  private onBlur = () => {
    this.setState({
      isFocused: false
    });
  };

  public render = () => {
    const hasFiles = this.state.files.length > 0;
    return (
      <Fragment>
        <input
          {...this.props}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          className="input input__file visually-hidden"
          type="file"
          multiple={false}
        />
        <label
          style={{ display: "inline-block" }}
          className={`${this.state.isFocused ? " label--focus" : ""}`}
          htmlFor={this.props.id}
        >
          <span className="button" role="button">
            Choose File
          </span>
          <span
            className={`input input__text${
              hasFiles ? "" : " input__text--disabled"
            }`}
          >
            {hasFiles ? this.state.files[0].name : "No file chosen"}
          </span>
        </label>
      </Fragment>
    );
  };
}

export default FileInput;
