import "./Link.css";
import { Link as GatsbyLink } from "gatsby";
import * as React from "react";
import { FunctionComponent } from "react";
import * as PropTypes from "prop-types";

export interface LinkProps {
  href: string;
  alt?: string;
  title?: string;
  className?: string;
  activeClassName?: string;

  target?: "_blank";
  rel?: "noreferrer";
}

const Link: FunctionComponent<LinkProps> = (props): JSX.Element => {
  const { href, className, ..._props } = props;
  const isInternalLink = /^\/(?!\/)/.test(props.href);
  const classNames = className ? `link ${className}` : "link";
  if (isInternalLink) {
    return (
      <GatsbyLink {..._props} className={classNames} to={href}>
        {props.children}
      </GatsbyLink>
    );
  } else {
    return (
      <a {...props} className={classNames} href={href}>
        {props.children}
      </a>
    );
  }
};

Link.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string
};

export default Link;
