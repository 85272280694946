/* istanbul ignore file */

import * as React from "react";
import { useState, useEffect, createRef } from "react";
import * as ReactDOM from "react-dom";
import * as PropTypes from "prop-types";
import debounce from "lodash.debounce";
import {
  InstantSearch,
  Index,
  Hits,
  connectSearchBox,
  connectStateResults
} from "react-instantsearch-dom";
import { Heading, Input as BaseInput, Tile } from "../../../components";
const algoliasearch = require("algoliasearch/lite");
import * as hitComps from "./hitComps";

import "./SiteSearch.css";

const Input = connectSearchBox(({ refine, ...rest }) => {
  const debounced = debounce(refine, 200);
  return (
    <BaseInput
      type="text"
      placeholder="Search"
      aria-label="Search"
      onChange={e => debounced(e.target.value)}
      {...rest}
    />
  );
});
const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) =>
    res && res.nbHits > 0 ? children : `No results for '${state.query}'`
);
const Stats = connectStateResults(
  ({ searchResults: res }) =>
    res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`
);
const useClickOutside = (ref, handler, events?) => {
  if (!events) events = [`mousedown`, `touchstart`];
  const detectClickOutside = event => {
    ref.current &&
      // eslint-disable-next-line react/no-find-dom-node
      !ReactDOM.findDOMNode(ref.current).contains(event.target) &&
      handler();
  };
  useEffect(() => {
    for (const event of events)
      document.addEventListener(event, detectClickOutside);
    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside);
    };
  });
};

export default function Search({ indices }) {
  const ref = createRef();
  const [query, setQuery] = useState(``);
  const [focus, setFocus] = useState(false);
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );
  useClickOutside(ref, () => {
    setFocus(false);
  });
  return (
    <div className="search_wrapper">
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        searchState={{ query }}
        onSearchStateChange={({ query }) => setQuery(query)}
        root={{ Root: "div", props: { ref } }}
      >
        <Input onFocus={() => setFocus(true)} />
        {query.length > 0 && focus && (
          <Tile className="search_results">
            {indices.map(({ name, title, hitComp }) => (
              <Index key={name} indexName={name}>
                <header>
                  <Heading level={6}>{title}</Heading>
                  <Stats />
                </header>
                <Results>
                  <Hits
                    hitComponent={hitComps[hitComp](() => setFocus(false))}
                  />
                </Results>
                <hr />
              </Index>
            ))}
          </Tile>
        )}
      </InstantSearch>
    </div>
  );
}

Search.propTypes = {
  indices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      hitComp: PropTypes.string.isRequired
    })
  )
};
