import * as React from "react";
import { FunctionComponent } from "react";

type NumberInputProps = {} & JSX.IntrinsicElements["input"];

const NumberInput: FunctionComponent<NumberInputProps> = (
  props
): JSX.Element => {
  return <input {...props} className="input input__text" type="number" />;
};

export default NumberInput;
