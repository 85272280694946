import "./Label.css";
import * as React from "react";
import { FunctionComponent, ReactNode } from "react";
import * as PropTypes from "prop-types";

type LabelType = "error" | "success" | "notification";

interface LabelProps {
  children?: ReactNode;
  type?: LabelType;
}

const Label: FunctionComponent<LabelProps> = (props): JSX.Element => {
  const labelClassNames = props.type ? `label label--${props.type}` : `label`;
  if (props.type) {
    return <span className={labelClassNames}>{props.children}</span>;
  }

  return <label className={labelClassNames}>{props.children}</label>;
};

Label.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf<LabelType>(["error", "success", "notification"])
};

export default Label;
