import * as React from "react";
import { Component, FunctionComponent } from "react";
import * as PropTypes from "prop-types";
import { Button, Link, PageContent } from "../../components";
import { Location } from "@reach/router";
import SiteSearch from "./SiteSearch/SiteSearch";

import "./Header.css";
import logoSrc from "../../images/logo.png";
import dismissIconSVG from "../../images/dismiss-icon.svg";
import menuCloseIconSrc from "../../images/menu-mobile-close-icon.png";
import menuOpenIconSrc from "../../images/menu-mobile-icon.png";
import covid19Letter from "../../pdfs/covid19-letter.pdf";
import Heading from "../Heading/Heading";

const Cookies = require("../../utilities/cookies");
const supportedBrowsersRegExp = require("../../utilities/supportedBrowsers");

const searchIndices = [
  { name: `Site Search`, title: `Pages`, hitComp: `SearchHit` },
  { name: `News Search`, title: `Posts`, hitComp: `PostHit` }
];

interface NavigationItemProps {
  children?: Button | Link;
}

const NavigationItem: FunctionComponent<NavigationItemProps> = (
  props
): JSX.Element => {
  return (
    <Location>
      {({ location }) => (
        <li
          className={
            location.pathname === props.children.props.href ? "active" : null
          }
        >
          {props.children}
        </li>
      )}
    </Location>
  );
};
NavigationItem.propTypes = {
  children: PropTypes.node
};

interface State {
  show_banner: boolean;
}

class Header extends Component<{}, State> {
  public static propTypes = {
    children: PropTypes.node
  };

  public constructor(props) {
    super(props);

    this.state = {
      show_banner: !Cookies.get("dismiss_outdated_banner")
    };
  }

  public componentDidMount(): void {
    const menuItems = document.querySelectorAll("nav.header-nav");
    Array.prototype.forEach.call(menuItems, el => {
      el.querySelector("button").addEventListener("click", function(event) {
        if (this.parentNode.className == "header-nav") {
          this.parentNode.className = "header-nav open";
          this.parentNode
            .querySelector("a")
            .setAttribute("aria-expanded", "true");
          this.parentNode
            .querySelector("button")
            .setAttribute("aria-expanded", "true");
        } else {
          this.parentNode.className = "header-nav";
          this.parentNode
            .querySelector("a")
            .setAttribute("aria-expanded", "false");
          this.parentNode
            .querySelector("button")
            .setAttribute("aria-expanded", "false");
        }
        event.preventDefault();
      });
    });
  }

  public render(): JSX.Element {
    return (
      <div className="header__layer">
        <header className="header">
          <PageContent>
            <Link href="/" className="logo">
              <img
                src={logoSrc}
                height="48"
                width="209"
                alt="Hawaiiana Management Company homepage"
              />
            </Link>
            <p aria-hidden>Hawaiiana Management Company, Ltd.</p>
            <nav className="header-nav" aria-label="Main">
              <a>Mobile navigation icon</a>
              <button className="mobile-nav-button">
                <img
                  className="mobile-open-icon"
                  src={menuOpenIconSrc}
                  alt="Show main navigation"
                />
                <img
                  className="mobile-close-icon"
                  src={menuCloseIconSrc}
                  alt="Hide main navigation"
                />
              </button>
              <ul>
                <NavigationItem>
                  <Link href="/">Home</Link>
                </NavigationItem>
                <NavigationItem>
                  <Link href="/find-a-community">Find a Community</Link>
                </NavigationItem>
                <NavigationItem>
                  <Link href="/resources">Resources</Link>
                </NavigationItem>
                <NavigationItem>
                  <Link href="/frequently-asked-questions">FAQs</Link>
                </NavigationItem>
                <NavigationItem>
                  <Link href="/careers">Careers</Link>
                </NavigationItem>
                <NavigationItem>
                  <Link href="/about">About</Link>
                </NavigationItem>
                <NavigationItem>
                  <Link href="/news">News</Link>
                </NavigationItem>
                <NavigationItem>
                  <Link href="/contact-us">Contact</Link>
                </NavigationItem>
                <NavigationItem>
                  <Button href="/make-a-payment">Make a Payment</Button>
                </NavigationItem>
                <NavigationItem>
                  <SiteSearch indices={searchIndices} />
                </NavigationItem>
              </ul>
            </nav>
          </PageContent>
          {((this.state.show_banner &&
            typeof window !== "undefined" &&
            !supportedBrowsersRegExp.test(navigator.userAgent)) ||
            typeof window === "undefined") && (
            <div className="banner">
              <PageContent>
                <p>
                  This site is best used with an up-to-date browser.{" "}
                  <em>
                    Please download{" "}
                    <Link
                      title="Download the latest version of the Chrome browser"
                      href="https://www.google.com/chrome/"
                    >
                      Chrome
                    </Link>{" "}
                    or{" "}
                    <Link
                      title="Download the latest version of the Firefox browser"
                      href="https://www.mozilla.org/en-US/firefox/new/"
                    >
                      Firefox
                    </Link>{" "}
                    for best results.
                  </em>
                </p>
                {typeof window !== "undefined" && (
                  <Button
                    style={{
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: "transparent"
                    }}
                    onClick={this.dismissCookie}
                  >
                    <img src={dismissIconSVG} alt="" /> Dismiss
                  </Button>
                )}
              </PageContent>
            </div>
          )}
        </header>
      </div>
    );
  }

  private dismissCookie = () => {
    Cookies.set("dismiss_outdated_banner", true);

    this.setState({
      show_banner: !Cookies.get("dismiss_outdated_banner")
    });
  };
}

export default Header;
