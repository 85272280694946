import * as React from "react";
import { FunctionComponent, PropsWithChildren } from "react";
import * as PropTypes from "prop-types";
import * as classnames from "classnames";

import "./Heading.css";

type HeadingProps = PropsWithChildren<
  {
    level?: number;
  } & React.HTMLAttributes<HTMLHeadingElement>
>;

const Heading: FunctionComponent<HeadingProps> = (props): JSX.Element => {
  const { level, className, children, ..._props } = props;
  const computedClassName = classnames("heading", className);

  switch (level) {
    case 6:
      return (
        <h6 className={computedClassName} {..._props}>
          {children}
        </h6>
      );
    case 5:
      return (
        <h5 className={computedClassName} {..._props}>
          {children}
        </h5>
      );
    case 4:
      return (
        <h4 className={computedClassName} {..._props}>
          {children}
        </h4>
      );
    case 3:
      return (
        <h3 className={computedClassName} {..._props}>
          {children}
        </h3>
      );
    case 2:
      return (
        <h2 className={computedClassName} {..._props}>
          {children}
        </h2>
      );
    case 1:
    default:
      return (
        <h1 className={computedClassName} {..._props}>
          {children}
        </h1>
      );
  }
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  children: PropTypes.node,
  className: PropTypes.string
};

export default Heading;
