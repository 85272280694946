import "./Sidebar.css";

import * as React from "react";
import { FunctionComponent, ReactNode } from "react";
import * as PropTypes from "prop-types";

interface SidebarProps {
  children?: ReactNode;
}

const Sidebar: FunctionComponent<SidebarProps> = (props): JSX.Element => {
  return <aside className="sidebar">{props.children}</aside>;
};

Sidebar.propTypes = {
  children: PropTypes.node
};

export default Sidebar;
