import * as React from "react";
import { Component } from "react";
import { Heading, Link, PageContent } from "..";
import "./Footer.css";

class Footer extends Component {
  public render(): JSX.Element {
    return (
      <footer className="footer">
        <PageContent>
          <nav aria-labelledby="about-us-menu-label">
            <Heading id="about-us-menu-label" level={6}>
              About Us
            </Heading>
            <ul>
              <li>
                <Link href="/our-team">Our Team</Link>
              </li>
              <li>
                <Link href="/find-a-community">Find Your Community</Link>
              </li>
              <li>
                <Link href="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </nav>
          <nav aria-labelledby="payments-menu-label">
            <Heading id="payments-menu-label" level={6}>
              Payments
            </Heading>
            <ul>
              <li>
                <Link href="/surepay-authorization-form">
                  Automated Payments (SurePay)
                </Link>
              </li>
              <li>
                <Link href="https://buttonstorage.net/hawaiiana-management-co-ltd-payment-by-echeck/">
                  E-Check Payment
                </Link>
              </li>
              <li>
                <Link href="https://buttonstorage.net/hawaiiana-management-co-ltd-payment-by-credit-cards/">
                  Credit/Debit Payment
                </Link>
              </li>
              <li>
                <Link href="/estatement-customer-service">
                  eStatement Inquiry
                </Link>
              </li>
              <li>
                <Link href="/surepay-cancellation-form">
                  Surepay Cancellation
                </Link>
              </li>
              <li>
                <Link href="/coupon-book-replacement/">
                  Request a new coupon book
                </Link>
              </li>
            </ul>
          </nav>
          <nav aria-labelledby="resources-and-links-menu-label">
            <Heading id="resources-and-links-menu-label" level={6}>
              Links
            </Heading>
            <ul>
              <li>
                <Link href="/resources/">Resources</Link>
              </li>
              <li>
                <Link href="/change-address/">Change Your Address</Link>
              </li>
              <li>
                <Link href="/request-a-proposal/">Request a Proposal</Link>
              </li>
              <li>
                <Link href="https://www.docutrieve.com/">Docutrieve</Link>
              </li>
              <li>
                <Link href="/frequently-asked-questions/">FAQs</Link>
              </li>
            </ul>
          </nav>
          <nav aria-labelledby="legal-menu-label">
            <Heading id="legal-menu-label" level={6}>
              Legal
            </Heading>
            <ul>
              <li>
                <Link href="/tos">Terms of Service</Link>
              </li>
              <li>
                <Link href="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link href="/rules">Rules and Regulations</Link>
              </li>
            </ul>
          </nav>
          <p>
            <small>Hawaiiana Management Company, Ltd.</small>
            <br />
            <small>©2000-{new Date().getFullYear()}.</small>
          </p>
        </PageContent>
      </footer>
    );
  }
}

export default Footer;
