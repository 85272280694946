import "./Button.css";
import Link from "../Link/Link";
import { LinkProps } from "../Link/Link";
import * as React from "react";
import { FunctionComponent } from "react";
import * as PropTypes from "prop-types";

type ButtonProps = {
  href?: string;
  ghost?: boolean;
} & JSX.IntrinsicElements["button"];

function isLinkProps(props: LinkProps | ButtonProps): props is LinkProps {
  return (props as LinkProps).href !== undefined;
}

const Button: FunctionComponent<ButtonProps | LinkProps> = (
  _props
): JSX.Element => {
  const {
    //@ts-ignore, not sure how to declare ghost as being optional
    ghost,
    children,
    ...props
  } = _props;
  const computedClassName = ghost ? "button button--ghost" : "button";
  if (isLinkProps(props)) {
    return (
      <Link className={computedClassName} {...props}>
        {children}
      </Link>
    );
  }
  return (
    <button className={computedClassName} {...props}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string
};

export default Button;
