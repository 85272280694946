import * as React from "react";
import * as PropTypes from "prop-types";

import "./PageBanner.css";

import { PageContent } from "..";

const PageBanner = props => {
  if (!props.isActive) {
    return null;
  }

  return (
    <div className="banner" style={{ marginBottom: "16px" }}>
      <PageContent
        dangerouslySetInnerHTML={{
          __html: props.body.childContentfulRichText.html
        }}
      />
    </div>
  );
};

PageBanner.propTypes = {
  body: PropTypes.shape({
    childContentfulRichText: PropTypes.shape({
      html: PropTypes.string
    })
  }),
  content: PropTypes.string,
  isActive: PropTypes.bool
};

PageBanner.defaultProps = {
  body: {
    childContentfulRichText: {
      html: ""
    }
  },
  content: null,
  isActive: true
};

export default PageBanner;
