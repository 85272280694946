/* istanbul ignore file */

import "./PageSidebar.css";
import { Footer, Header, PageContent } from "../../components";
import * as React from "react";
import { Component, ReactNode } from "react";
import * as PropTypes from "prop-types";
import { componentWithName } from "airbnb-prop-types";
import ReactHelmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

interface PageProps {
  layout?: string;
  sidebar?: ReactNode;
  title?: string;
}

function getLayoutClassNameFromProps(props: PageProps): string | null {
  if (!props.layout) {
    return null;
  }
  return `layout--${props.layout}`;
}

/**
 * The base page wrapper component.
 * More page layouts should be based off of this component.
 */
class PageSidebar extends Component<PageProps> {
  public static readonly propTypes = {
    children: PropTypes.node,
    layout: PropTypes.string,
    title: PropTypes.string,
    // TODO: Couldn't figure out how to check for actual Sidebar component.
    sidebar: componentWithName("Sidebar")
  };

  public static readonly defaultProps = {
    layout: "left-sidebar",
    sidebar: null
  };

  public render(): JSX.Element {
    const { layout } = this.props;
    let layoutClassName = getLayoutClassNameFromProps(this.props);

    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                description
              }
            }
          }
        `}
        render={data => (
          <div id="site__container" className={layoutClassName}>
            <ReactHelmet>
              <title>{this.props.title}</title>
              <meta
                name="description"
                content={data.site.siteMetadata.description}
              />
            </ReactHelmet>
            <Header />
            {layout === "left-sidebar" && this.props.sidebar}
            <main role="main">
              {/* <div className="banner" style={{ marginBottom: "16px" }}>
                <PageContent style={{ textAlign: "center" }}>
                  <a
                    title="Visit our news page for Hawaiiana’s COVID-19 Update"
                    href="/news"
                    style={{
                      textDecorationColor: "red"
                    }}
                  >
                    <em
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "2em"
                      }}
                    >
                      Click here for Hawaiiana’s COVID-19 Update
                    </em>
                  </a>
                </PageContent>
              </div> */}
              {this.props.children}
            </main>
            {layout === "right-sidebar" && this.props.sidebar}
            <Footer />
          </div>
        )}
      />
    );
  }
}

export default PageSidebar;
