import "./TextInput.css";
import * as React from "react";
import { FunctionComponent } from "react";

type TextInputProps = {
  currentRefinement?;
  isSearchStalled?;
  createURL?;
} & JSX.IntrinsicElements["input"];

const TextInput: FunctionComponent<TextInputProps> = (_props): JSX.Element => {
  const {
    // Ignore instasearch search props that are causing errors
    currentRefinement,
    isSearchStalled,
    createURL,
    ...props
  } = _props;
  return <input {...props} className="input input__text" />;
};

TextInput.defaultProps = {
  type: "text"
};

export default TextInput;
