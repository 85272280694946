import classnames from "classnames";
import * as React from "react";
import { FunctionComponent } from "react";
import * as PropTypes from "prop-types";

import "./List.css";

type ListProps = React.HTMLAttributes<HTMLOListElement | HTMLUListElement> & {
  ordered?: boolean;
};

const List: FunctionComponent<ListProps> = props => {
  const { children, className, ordered, ..._props } = props;

  let ListElement = null;
  if (ordered) {
    ListElement = "ol";
  } else {
    ListElement = "ul";
  }

  return (
    <ListElement
      className={classnames(
        "list",
        {
          "list--unordered": !ordered,
          "list--ordered": ordered
        },
        className
      )}
      {..._props}
    >
      {children}
    </ListElement>
  );
};

List.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  ordered: PropTypes.bool
};

List.defaultProps = {
  ordered: false
};

export default List;
