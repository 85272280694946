import "./TextArea.css";
import * as React from "react";
import { FunctionComponent } from "react";

type TextAreaProps = {} & JSX.IntrinsicElements["textarea"];

const TextArea: FunctionComponent<TextAreaProps> = (props): JSX.Element => {
  return <textarea {...props} className="input__text" />;
};

export default TextArea;
