import * as React from "react";

import "./Paragraph.css";

const Paragraph: React.FunctionComponent<
  React.HTMLAttributes<HTMLParagraphElement>
> = function(_props) {
  const { children, ...props } = _props;

  return (
    <p className="paragraph" {...props}>
      {children}
    </p>
  );
};

export default Paragraph;
