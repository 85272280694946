import * as React from "react";
import * as PropTypes from "prop-types";
import * as classnames from "classnames";

import "./Content.css";

const PageContent: React.FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = props => {
  const { className, children, ..._props } = props;
  return (
    <div className={classnames("page__content", className)} {..._props}>
      {children}
    </div>
  );
};

PageContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default PageContent;
