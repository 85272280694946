import "./InputHelper.css";
import * as React from "react";
import { FunctionComponent } from "react";
import * as PropTypes from "prop-types";

type InputHelperProp = {
  id: string;
  label?: string;
  cb: any;
} & JSX.IntrinsicElements["button"];

const InputHelper: FunctionComponent<InputHelperProp> = (
  props
): JSX.Element => {
  return (
    <div className="input-helper">
      <button
        className="input-helper__button--hidden"
        aria-label="Toggle input hint"
        id={props.id}
        onClick={() => props.cb(props.id)}
        type="button"
      >
        {props.label}
      </button>
      <div onClick={() => props.cb(props.id)} tabIndex={0}>
        <div className="input-helper__dialog-wrapper">{props.children}</div>
      </div>
    </div>
  );
};

InputHelper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  cb: PropTypes.func
};

export default InputHelper;
